<template>
  <div class="home">
    <main>
      <h1>Jugendfeuerwehr Lunestedt</h1>
    <article class="youthmain">
      
      <h2>Allgemeine Infos zur Jugendfeuerwehr</h2><p>
      Die Jugendfeuerwehr Lunestedt wurde 1964 gegründet.
      Sie gehört neben der Kinderfeuerwehr zum Nachwuchs der Freiwilligen Feuerwehr Lunestedt.
      10 Mädchen und 18 Jungen im Alter von 10 – 18 Jahren treffen sich wöchentlich 
      jeden Dienstag (Sommerzeit 18:30 Uhr – 20:00 Uhr / Winterzeit 18:30 – 19:30 Uhr) im und am Feuerwehrhaus zum Übungsdienst. 
      Geleitet wird die JF vom Jugendwart Manuel Molzen, seiner Stellvertreterin Sabrina Schmidt und einem großen Betreuerteam.
      <br></p>
      <div><button @click="isVisible = !isVisible">Bilder</button>
      <div class="bild_parent" v-if="isVisible">
        <div v-for="item in allgemein" :key="item" class="bilder">
          <img :src="require(`@/components/images/jugend/allgemeines/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div> 
      
      <br>
      <h2>Was macht die Jugenfeuerwehr</h2>
      <p>Bei den Übungsdiensten steht die feuerwehrtechnische Ausbildung im Vordergrund.
      Wie löscht man verschiedene Brände, wie schützt man sich beim Einsatz, welche Geräte werden wofür benötigt, welche Fahrzeuge gibt es und wie legt man einen Verband an. Dies und vieles mehr, was man später als Feuerwehrfrau / -mann wissen muss, lernen die JF-Mitglieder.
      Aber auch die allgemeine Jugendarbeit kommt nicht zu kurz. Ausflüge, Spiele und Bastelaktionen stehen natürlich ebenfalls auf dem Dienstplan.
      Gerade diese Mischung fördert das Miteinander innerhalb der JF-Gruppe.
      <br></p>
      <div><button @click="isVisible1 = !isVisible1">Bilder</button>
      <div class="bild_parent" v-if="isVisible1">
        <div v-for="item in was" :key="item" class="bilder">
          <img :src="require(`@/components/images/jugend/was/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div>

      <br>
      <h2>Wettbewerbe und Abzeichen der Jugendfeuerwehr</h2>
      <p>Das gelernte Feuerwehrwissen kann bei Wettbewerben und Prüfungen gezeigt werden.
      Zu den Wettbewerben gehört die Ausführung eines Löschangriffs und die Durchführung eines Staffellaufs mit kleinen feuerwehrtechnischen Aufgaben. Diese Wettbewerbe finden jährlich auf Kreisebene und alle 2 Jahre auf Gemeinde- und Abschnittebene statt.
      Zu den Prüfungen gehören die Jugendflamme Stufe 1-3 und die Leistungsspange.
      Bei der Jugendflamme Stufe 1-3 zeigen die JF Mitglieder einzeln oder in kleinen Gruppen ihr theoretisches und praktisches Wissen. Sie müssen z.B. verschiedene Feuerwehrgeräte benennen und erklären, Feuerwehrknoten anfertigen, Schläuche perfekt rollen und den Notruf absetzen können.
      Die Leistungsspange ist die höchste Auszeichnung in der Jugendfeuerwehr und kann von JF Mitgliedern ab 15 Jahren absolviert werden.
      Die Prüfung besteht aus mehreren Aufgaben, einem Löschangriff, einem reinem Staffellauf, Kugelstoßen, einer Schnelligkeitsübung und einer Fragerunde (feuerwehrtechnisches aber auch allgemeines Wissen).
      <br></p>
      <div><button @click="isVisible2 = !isVisible2">Bilder</button>
      <div class="bild_parent" v-if="isVisible2">
        <!--<div class="bilder"><img src="@/components/images/jugend/wettbewerbe/ATeilGruppeGroß.jpeg"/></div>
        <div class="bilder"><img src="@/components/images/jugend/wettbewerbe/ATeilgroß.jpeg"/></div>
        <div class="bilder"><img src="@/components/images/jugend/wettbewerbe/ATeilgroßaktiv.jpeg"/></div>
        <div class="bilder"><img src="@/components/images/jugend/wettbewerbe/ATeilGruppeGroß.jpeg"/></div>
        <div class="bilder"><img src="@/components/images/jugend/wettbewerbe/ATeilGruppeGroß.jpeg"/></div>
        <div class="bilder"><img src="@/components/images/jugend/wettbewerbe/ATeilGruppeGroß.jpeg"/></div>
        <div class="bilder"><img src="@/components/images/jugend/wettbewerbe/ATeilGruppeGroß.jpeg"/></div>-->
        <div v-for="item in wettbewerbe" :key="item" class="bilder">
          <img :src="require(`@/components/images/jugend/wettbewerbe/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div> 
      
      <br>
      <h2>Lager, Fahrten und Aktionen der Jugendfeuerwehr</h2>
      <p>Zu den besonderen Highlights gehören die Zeltlager.
      Alle 3 Jahre findet das große Kreiszeltlager mit ca. 900 Teilnehmer statt.
      Eine Woche voller Spiel und Spaß mit allen Jugendfeuerwehren aus dem Landkreis Cuxhaven und einigen Gastwehren.
      Aber auch das kleinere Gemeindezeltlager mit den Jugendfeuerwehren aus der Gemeinde Beverstedt ist sehr beliebt.
      Weitere Aktionen wie der Kreiskinotag, Übernachtungen im Feuerwehrhaus, Ausflüge zur Berufsfeuerwehr, Besuch eines Freizeitparks, Bastelaktionen, Spieleabende oder die Teilnahme am Lunestedter Umwelttag stärken das Wir-Gefühl der Jugendfeuerwehr.
      <br></p>
      <div><button @click="isVisible3 = !isVisible3">Bilder</button>
      <div class="bild_parent" v-if="isVisible3">
        <div v-for="item in freizeit" :key="item" class="bilder">
          <img :src="require(`@/components/images/jugend/freizeit/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div> 
    
      <br>
      <h3>Kontakte</h3>
      <p>Farina Siedenburg, Jugendwartin</p>
      <p>Sabrina Schmidt, stellv. Jugendwartin</p>
      <P>E-Mail: &#74;&#117;&#103;&#101;&#110;&#100;&#102;&#101;&#117;&#101;&#114;&#119;&#101;&#104;&#114;&#64;&#102;&#101;&#117;&#101;&#114;&#119;&#101;&#104;&#114;&#45;&#108;&#117;&#110;&#101;&#115;&#116;&#101;&#100;&#116;&#46;&#100;&#101;</P>
    </article>
</main>
  </div>
</template>

<script>

export default {
  name: 'JugendFeuer',
  data() {
    return {
      isVisible: false,
      isVisible1: false,
      isVisible2: false,
      isVisible3: false,
      //Hier Bilder allgemein zur JF (Lunestedt) einfügen
      allgemein: [
        {url: 'Grupprnbildersatz.jpg', name: 'Gruppenbild JF Lunestedt'},
      ],
      //Hier Bilder zum Übungsdienst und was die JF macht einfügen
      was: [
        {url: 'ÜbungsdiensteSep21.jpg', name: 'Übungsdienst September 21'},
        {url: 'ÜbungsdiensteFeb23.jpg', name: 'Übungsdienst Februar 23'},
        {url: 'ÜbungsdiensteFeb23(2).jpg', name: 'Übungsdienst Februar 23'},
        {url: 'ÜbenJugendflamme2.jpg', name: 'Übung für die Jugendflamme 2'},
      ],
      //Hier Bilder zu den Wettbewerben einfügen
      wettbewerbe: [
        {url: 'ATeilGruppeGroß.jpg', name: 'KJF-Tag in Hollen Lunestedt 1'},
        {url: 'ATeilgroßaktiv.jpg', name: 'KJF-Tag in Hollen Lunestedt 1'},
        {url: 'KJF-TagHollen.jpg', name: 'KJF-Tag in Hollen Lunestedt 1 in Aktion'},
        {url: 'KJF-TagHollen(2).jpg', name: 'KJF-Tag in Hollen Lunestedt 1 in Aktion'},
      ],
      //Hier Bilder zur Freizeit Aktivitäten sowie Lager und Fahrten einfügen
      freizeit: [
        {url: 'Kinoaktion.jpg', name: 'Kinotag September 2022'},
        {url: 'Kürbisschnitzen2022.jpg', name: 'Kürbisschnitzen 2022'},
        {url: 'Kürbisschnitzen2022(2).jpg', name: 'Kürbisschnitzen 2022'},
        {url: 'SpielohneGrenzenHeerstedt.jpg', name: 'Spiele ohne Grenzen in Heerstedt'},
        {url: 'SpielohneGrenzenHeerstedt2.jpg', name: 'Spiele ohne Grenzen in Heerstedt'},
        {url: 'Gemeindezeltlager(1).jpg', name: 'Gemeindezeltlager 23 in Lunestedt'},
        {url: 'Gemeindezeltlager(2).jpg', name: 'Gemeindezeltlager 23 in Lunestedt'},
        {url: 'Gemeindezeltlager(3).jpg', name: 'Gemeindezeltlager 23 in Lunestedt'},
      ],
    }
  },
  components: {
  },
  methodes: {
  }
}
</script>
<style scoped>
.youthmain {
  margin-top: 20px;
}
.bild_parent {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border: solid 1px;
  border-radius: 5px;
  border-color: var(--inverted-color);
  padding-bottom: 15px;
  margin-top: 10px;
}
.bilder {
  width: 20%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 15px;
}
img {
  max-width: 100%;
  height: auto;
}
button {
  border-radius: 5px;
  border-color: var(--inverted-color);
  background-color: transparent;
  font-size: 20px;
  color: var(--inverted-color);
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: 0.3s;
}
button:hover {
  color: var(--complementary-color);
  transition: 0.3s;
}
h2 {
  color: var(--primary-color);
  margin-top: 50px;
}
h3 {
  margin-top: 50px;
}
</style>
